<template>
    <ContentField>
        <div class="container">
            <form @submit.prevent="register">
                <div class="mb-3">
                    <span>已有号牌: </span>
                    <span class="brief" v-for="codeid in codeid_list" :key="codeid" style=" margin-left: 6px;">
                        {{ codeid }}
                    </span>
                    <br>
                    <label for="usercode" class="form-label" style="margin-top: 15px;">请输入你想要的号牌:</label>
                    <input v-model="usercode" type="text" class="form-control" id="usercode">
                </div>
                <div class="d-grid gap-2 col-6 mx-auto">
                    <button @click="submit_code()" type="submit" class="btn btn-primary">查询</button>
                </div>
                <div class="status-info">当前的号牌是:
                    {{ reback_id }} </div>
            </form>

            <div class="text-center picture">
                <img v-bind:src="posts.url" style="text-align: center;
                    margin-top: 20px ;" class="rounded" alt="showup">
            </div>
        </div>
    </ContentField>
</template>

<script>
import ContentField from '../components/ContentField.vue'
import { reactive } from 'vue';
import { ref } from 'vue'
import $ from 'jquery'

export default {
    components: {
        ContentField,
    },
    beforeRouteEnter(to, from, next) {        // 动态修改标签页title
        document.title = to.meta.title;    // document是一个全局对象，即DOM 
        //  .title 也可以直接接字符串 '' , to.meta 是在router js中设置的
        next() // 执行页面跳转
    },
    setup() {
        let usercode = ref('');
        let id = usercode.value;
        let posts = reactive({});
        let reback_id = ref('');
        let is_clicked = false;
        let codeid_list = ref('');
        $.ajax({
            url: 'http://pcat.one/hacker/list/',
            type: 'GET',
            data: {},
            success(resp) {
                codeid_list.value = resp;
            }
        })

        const submit_code = () => {
            console.log(usercode.value);
            $.ajax({
                url: ("http://pcat.one/hacker/" + usercode.value + '/'),
                type: "GET",
                data: {

                },
                success(resp) {
                    console.log(resp)
                    is_clicked = true;
                    // console.log(is_clicked.value)
                    reback_id.value = resp.code_id;
                    posts.url = resp.picture;
                    posts.url = "http://pcat.one" + posts.url;
                    console.log(posts.url);
                }
            })
        };

        return {
            submit_code,
            usercode,
            posts,
            id,
            reback_id,
            is_clicked,
            codeid_list,
        }
    }

}
</script>

<style scoped>
.status-info {
    text-align: center;
    margin-top: 15px;
    font-size: 16px;
}
</style>