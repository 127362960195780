import http from "@/main";

function getSimpleToDoList() {
    return new Promise((resolve) => {
        http.get('/todos/get_simple_todolist/')
            .then(res => {
                resolve(res.data);
            })
    });
}

function getToDoById(id) {
    return new Promise((resolve) => {
        http.get('/todos/' + id + "/")
            .then(res => {
                resolve(res.data);
            })
    });
}


const ToDoViewService = {
    getSimpleToDoList,
    getToDoById
}
export default ToDoViewService 