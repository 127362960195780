<template>
    <div class="container" style="margin-top: 64px;">
        <div v-for="project in projects" :key="project" @click="gotobil(project.goto_url)" class="tutorial-box">
            <img src="http://pcat.one/upload/img/head.png" alt="" style="height: 120px; border-radius: 50%; margin: 10px ;
            float: left">
            <h5 style="padding-top: 35px; padding-left: 150px;">{{ project.title }}</h5>
            <div class="brief" style="font-size: 15px; padding-left: 150px ; ">{{ project.brief
                }}
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import http from "@/main";

export default {
    beforeRouteEnter(from, to, next) {
        document.title = '教程';
        next();
    }, setup() {
        let projects = ref('');
        function gotobil(goto_url) {
            console.log(goto_url);
            window.open(goto_url)
        }

        http.get("/tutorial/")
            .then(res => {
                projects.value = res.data
            })
        return {
            gotobil,
            projects,
        }
    }
}
</script>

<style scoped>
.tutorial-box {
    height: 140px;
    background-color: rgba(31, 34, 40, 0.6);
    margin-top: 20px;
    border-radius: 7px;
    font-family: "宋体", SimSun, serif;
}

.tutorial-box:hover {
    transform: scale(1.02);
    transition: 0.2s linear;
    box-shadow: 0 0 10px #888;
    cursor: grab;
}
</style>