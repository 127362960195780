import http from "@/main";

function SearchByTag(tag) {
    return new Promise((resolve) => {
        http.get('/search_by_tag/?tag=' + tag)
            .then(res => {
                resolve(res.data);
            })
    });
}

const SearchPostViewService = {
    SearchByTag,

}
export default SearchPostViewService