import { createRouter, createWebHistory } from 'vue-router'
import NotFound from '../views/error/NotFound'
import RankListIndexView from '../views/ranklist/RanklistIndexView'
import PkIndexView from '../views/pk/PkIndexView'
import BlogPostView from '../views/post/BlogPostView'
import SearchPostView from '../views/post/SearchPostView'
import OnlineCompiler from '../views/compile/OnlineCompiler.vue'
import PasteBinView from '../views/pastebin/PasteBinView'
import ContestInfoView from '../views/ContestInfoView'
import HackerView from '../views/HackerView'
import TodoView from '../views/TodoView'
import Mytutorial from '../views/MytutorialView.vue'
import SubmitPaste from '../views/pastebin/SubmitPaste'
import PasteItView from '../views/pastebin/PasteItView'
import RegisterUser from '../views/account/RegisterUser'
import GetTableView from '../views/GetTableView.vue'
import GetAnswerView from '../views/GetAnswerView.vue'
import LoginUser from '../views/account/LoginUser.vue'
import GetAnswerAdmin from '../views/getAnswer/GetAnswerAdmin.vue'
import NavMysite from '../views/NavSuperView.vue'


const routes = [
  {
    path: "/",
    name: "root",
    component: PkIndexView,
  },
  {
    path: "/myspace/",
    name: "home",
    redirect: { name: "pk_index" },
  },
  {
    path: "/getAnswer/",
    name: "getAnswer",
    component: GetAnswerView,
  },
  {
    path: "/myspace/register/",
    name: "register",
    component: RegisterUser,
  },
  {
    path: "/myspace/ranklist/",
    name: "ranklist_index",
    component: RankListIndexView,
  },
  {
    path: "/myspace/pk/",
    name: "pk_index",
    component: PkIndexView,
  },
  {
    path: "/myspace/record/1",
    name: "record_index",
    component: BlogPostView,
  },
  {
    path: "/myspace/record/:recordId",
    name: "record_index_next",
    component: BlogPostView,
  },
  {
    path: "/myspace/404/",
    name: "notfound",
    component: NotFound,
  },
  {
    path: "/myspace/compiler/",
    name: "compiler",
    component: OnlineCompiler,
  },
  {
    path: "/myspace/contestInfo/",
    name: "contestInfo",
    component: ContestInfoView,
  },
  {
    path: "/myspace/pastebin/",
    name: "pastebin",
    component: PasteBinView,
  },
  {
    path: "/myspace/submitcode/",
    name: "submitcode",
    component: SubmitPaste,
  },
  {
    path: "/blog/context/:ArticleId/",
    name: "articleprofile",
    component: PasteBinView,
  },
  {
    path: "/pastebin/:PasteId/",
    name: "pasteRedirect",
    component: PasteBinView,
  },
  {
    path: "/tmp/:PasteId/",
    name: "pasteTmp",
    component: PasteItView,
  },
  {
    path: "/myspace/hacker",
    name: "hacker",
    component: HackerView,
    meta: { title: '定向图片' }
  },
  {
    path: "/myspacce/todo",
    name: "todo",
    component: TodoView,
  },
  {
    path: "/myspace/tutorial",
    name: "tutorial",
    component: Mytutorial,
  },
  {
    path: "/myspace/jiaowu",
    name: "gettable",
    component: GetTableView,
  },
  {
    path: "/myspace/login",
    name: "login",
    component: LoginUser,
  },
  {
    path: "/myspace/getAnswer",
    name: "getanswer",
    component: GetAnswerView,
  },
  {
    path: "/getAnswer/admin/",
    name: "getanswerAdmin",
    component: GetAnswerAdmin,
  },
  {
    path: "/myspace/navbar/",
    name: "navmysite",
    component: NavMysite,
  },
  {
    path: "/post/search/:mytag/",
    name: "searchPost",
    component: SearchPostView,
  },
  {
    path: "/myspace/:catchAll(.*)",
    redirect: "/myspace/404/"
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
