<template>
    <ContentField>
        <div>Search Results...</div>
        <div class="rightside">

            <div class="container">

                <!-- 动态展示文章列表 -->
                <div v-for="article in result" :key="article.id" class="blog-box" style="display: flex;"
                    @click="OpenArticle(article.id)">
                    <div class="blog-box-left" style="height: auto; width: 35%;">
                        <img :src="article.pic_url" :srcset="article.pic_url" sizes="(max-width: 600px) 480px,
                        800px" alt="blog-info-pic" class="blog-box-pic" @click="OpenArticle(article.id)">
                    </div>

                    <div class="blog-box-right-info">
                        <div class="blog-box-right-info-title" @click="OpenArticle(article.id)">{{ article.title }}
                        </div>
                        <div class="blog-box-right-info-date">
                            {{ article.createtime.substr(0, 10) + " " +
                    article.createtime.substr(11, 8) }}</div>

                        <!-- 文章标签 -->
                        <div class="tagdiv" style="margin-top: 10%;">
                            <span v-for="tag in article.tags" :key="tag" class="tag"> {{ tag }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ContentField>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router';
import ContentField from '../../components/ContentField.vue'
import router from '@/router';
import SearchPostViewService from '@/api/SearchPostViewService'
export default {
    components: {
        ContentField,
    },
    setup() {
        const route = useRoute();
        const mytag = route.params.mytag

        let result = ref('');
        (async () => {
            let data = await SearchPostViewService.SearchByTag(mytag);
            result.value = data;
        })();

        const OpenArticle = ArticleId => {

            router.push({
                name: "articleprofile",  // 这是按照路由的name匹配而不是路由的字符
                params: {
                    ArticleId, // 路由那里也是名字匹配 我服了
                },
            })
        }

        return {
            mytag, result, OpenArticle
        }
    }
}


</script>

<style scoped>
.search-box {
    margin: 5% 0 5% 0;
}

.tag {
    font-size: 19px;
    color: rgb(52, 145, 250);
    background-color: #a5cef7;
    font-weight: lighter;
    padding-left: 8px;
    padding-right: 8px;
    border: 0.1rem solid rgb(52, 145, 250);
    border-radius: 5px;
    margin-left: 13px;
}

.blog-box-pic {
    height: auto;
    margin-top: 5%;
}

.blog-box-right-info-title {
    font-size: 20px;
}

.leftside {
    width: 20%;
    margin-left: 10%;
    /* margin-top: 20px;
    width: 20%;
    margin-left: 10%; */
}

.rightside {
    width: 60%;
    margin-right: 10%;
}

.tag {
    font-size: 19px;
    color: rgb(52, 145, 250);
    background-color: #a5cef7;
    font-weight: lighter;
    padding-left: 8px;
    padding-right: 8px;
    border: 0.1rem solid rgb(52, 145, 250);
    border-radius: 5px;
    margin-left: 13px;
}

.btn:hover {
    color: rgb(66, 135, 240);
}

.blog-box {
    height: 20%;
    background-color: rgba(31, 34, 40, 0.6);
    margin-top: 20px;
    border-radius: 7px;
    font-family: Fira Code !important;
    font-size: 1.5em;
    border-radius: 8px;
    font-weight: bold;
}

.blog-box-right-info {
    padding-left: 5%;
    padding-top: 5%;
    color: #CBC6C0;
    width: 40%;
}

.blog-box-left {
    position: relative;
    display: inline-block;
    overflow: hidden;
    border-radius: 8px;
}

.blog-box-left img {
    display: block;
    max-width: 100%;
    transition: transform 0.3s ease-out;
    height: 100%;
    width: 100%;
}

.blog-box-left:hover img {
    transform: scale(1.2);
    cursor: pointer;
}

.blog-box-right-info-date {
    margin-top: 20px;
    font-size: 17px;
    color: rgb(233, 198, 133);
}

.blog-box-right-info-title:hover {
    color: pink;
    cursor: pointer;
}

.blog-categoryDetail:hover {
    color: pink;
    cursor: pointer;
}

.blog-box:hover {
    cursor: pointer;
}
</style>
