import http from "@/main";

function getArticleById(id) {
    return new Promise((resolve) => {
        http.get('/post/' + id + '/')
            .then(res => {
                resolve(res.data);
            })
    });
}

function postNewPasteBin(form_data) {
    return new Promise((resolve) => {
        http.post('/pastebin/', form_data)
            .then(res => {
                resolve(res.data);
            })
    });
}

function getPasteBinById(id) {
    return new Promise((resolve) => {
        http.get('/pastebin/' + id + '/')
            .then(res => {
                resolve(res.data);
            })
    });
}

const PasteBinViewService = {
    getArticleById,
    postNewPasteBin,
    getPasteBinById
}

export default PasteBinViewService