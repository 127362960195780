<template>
    <ContentField>
        <div class="head font-config">
            <h3> 标题：{{ title }}</h3>
            <div class="info" style="margin-top: 20px;">
                <div style="float: left;"> 作者： {{ author }}</div>
                <div style="float: right;"> 时间：{{ time }}</div>
            </div>

            <div class="tail" style="clear: both; margin-top: 70px;">
                <pre style="color: black;">{{ content }}</pre>
            </div>
        </div>


    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'
import { ref } from 'vue';
// import $ from 'jquery';
import { useRoute } from 'vue-router';
import PasteBinViewService from '@/api/PasteBinViewService'

export default {
    components: {
        ContentField,
    },
    setup() {
        let title = ref(''); let author = ref(''); let time = ref('');
        let content = ref('');

        const route = useRoute();
        const id = parseInt(route.params.PasteId);

        // 跳转到对应id的PasteBin
        (async (id) => {
            let resp = await PasteBinViewService.getPasteBinById(id);
            console.log(resp)

            title.value = resp.title;
            author.value = resp.author;
            content.value = resp.content;
            time.value = resp.createtime.substr(0, 10) + "   " + resp.createtime.substr(11, 8);
        })(id)

        return {
            title, author, time, id, content,
        }
    }
}
</script>

<style scoped>
.font-config {
    font-family: '宋体', SimSun, serif;
    color: rgb(233, 198, 133);
}

pre {
    background-color: rgb(210, 210, 210);
}
</style>