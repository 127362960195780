<template>
    <ContentField>
        <div class="GetAnswer-box" style="text-align: center; margin: auto; width: 60%;">

            <div class="title">抢答器管理界面</div>
            <button style="margin-top: 40px; width: 200px;" type="button" @click="set()"
                class="btn btn-primary">设置时间</button>

            <table class="table table-hover">
                <thead class="">
                    <tr>
                        <th># 排名</th>
                        <th>用户id</th>
                        <th>抢答时间</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="tableItem in tableItems" :key="tableItem" style="color: white;">
                        <td> {{ tableItem.id - first_id + 1 }}</td>
                        <td> {{ tableItem.user_id }} </td>
                        <td> {{ tableItem.TimeStamp }} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ContentField>
</template>

<script>
import $ from 'jquery'
import router from '@/router';
import ContentField from '../../components/ContentField.vue'
import { ref } from 'vue'

export default {
    components: {
        ContentField,
    },
    setup() {
        document.title = "抢答器 | 管理界面";
        let tableItems = ref(''); let first_id = ref('');

        let token = localStorage.getItem('access');
        if (token == undefined) {
            router.push('/myspace/login');
        }

        $.ajax({
            url: "http://pcat.one/getAnswer/",
            type: "GET",
            headers: {
                'Authorization': "Bearer " + token,
            },
            success(resp) {
                tableItems.value = resp;
                first_id.value = resp[0].id;
            },
            error() {
                router.push('/myspace/login');
            }
        })

        function set() {
            console.log("设置时间！");
            $.ajax({
                url: "http://pcat.one/setTimer/",
                type: "GET",
                success(resp) {
                    console.log(resp);
                }
            })
        }

        return {
            set, tableItems, first_id
        }
    }
}
</script>

<style scoped>
.table {
    margin-top: 30px;
    color: white;
}

table td {
    color: white;
}
</style>