import router from '@/router';
import $ from 'jquery';
import jwt_decode from 'jwt-decode';


const ModuleUser = {
    state: {
        id: "",
        username: "",
        photo: "",

        access: "",
        refresh: "",
        is_login: false,

        // info
        Subscribes: 0,
        followerCount: 0,
        articleCount: 0,
    },
    getters: {
    },
    mutations: {
        updateUser(state, resp) {
            console.log(resp);

            state.access = resp.access;
            state.refresh = resp.refresh;
            state.is_login = resp.is_login;
            state.username = resp.username;
        },
        updateAccess(state, access) {
            console.log("刷新本地存储！")
            state.access = access;
            localStorage.setItem('access', access);
        },
        logout(state) {
            state.id = "";
            state.username = "";
            state.photo = "";
            state.followerCount = 0;
            state.access = "";
            state.refresh = "";
            state.is_login = false;
        }
    },
    // actions 处理异步  mutations 处理赋值这样的操作 
    actions: {
        login(context, data) {
            console.log(context);

            $.ajax({
                url: "http://pcat.one/api/token/",
                type: "POST",
                data: {
                    username: data.username,
                    password: data.password,
                },
                success(resp) {
                    const { access, refresh } = resp;
                    const access_obj = jwt_decode(access);
                    let user_id = access_obj.user_id;

                    localStorage.setItem('access', access);
                    localStorage.setItem('refresh', refresh);
                    // context.commit("updateUser", { resp: resp });
                    // 这里封装成了payload，也就是载荷，mutation里面的resp是 { 载荷参数的集合 }

                    router.push({ name: "pk_index" });
                    setInterval(() => {
                        console.log("令牌过期，获取刷新令牌")
                        $.ajax({
                            url: "http://pcat.one/api/token/refresh/",
                            type: "POST",
                            data: {
                                refresh,
                            },
                            success(resp) {
                                context.commit('updateAccess', resp.access);
                            }
                        });
                    }, 4.5 * 60 * 1000);

                    this.set_login(access, user_id);
                },
                error() {
                    data.error();
                }
            });
        },

        set_login(context, obj) {
            // 拉去用户信息更新store 
            let user_id = obj.user_id, access = obj.access, refresh = obj.refresh;

            $.ajax({
                url: "http://pcat.one/getUser/" + user_id + "/",
                type: "GET",
                // headers: {
                //     'Authorization': "Bearer " + access,
                // },
                success(resp) {
                    console.log(resp);
                    console.log("in userjs ")
                    let username = resp.username;

                    context.commit("updateUser", {
                        username: username,
                        access: access,
                        refresh: refresh,
                        is_login: true,
                    })
                }
            })
        }
    },
    modules: {
    }
};

export default ModuleUser;
