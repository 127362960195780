<template>
    <div class="margin-box content-field">
        <div class="card blog-head">
            <div class="card-body">
                <img class="head-photo" src="http://pcat.one/upload/img/head.png" alt="headphoto">
                <div class="head-info">
                    <h4>Hnitrogen</h4>
                    <div style="margin-top: 20px;">个人情况：大三在读 | 目标： 美团众包</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

</script>

<style scoped>
.margin-box {
    color: #FFFFFF;
    margin-top: 64px;
}

.blog-context {
    margin-top: 20px;
}

.blog-head {
    margin-top: 10px;
}

.head-info {
    margin-left: 100px;
    margin-top: 20px;
}

.head-photo {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    float: left;
    box-sizing: content-box;
    padding-right: 30px;
}

.card {
    /* background-color: rgba(31,34,40,0.3); */
    background-color: rgba(31, 34, 40, 0.6)
}

.card-body {
    /* background-color: rgba(31,34,40,0.3); */
    background-color: rgba(31, 34, 40, 0.6);
}

/* 大屏幕样式规则 */
@media (min-width: 767px) {
    .margin-box {
        color: #FFFFFF;
        margin-top: 64px;
        width: 80%;
        margin-left: 10%;
    }

}


/* 小屏幕样式规则 */
@media (max-width: 767px) {
    .blog-head {

        .head-info {
            font-size: 10px;
        }

        .head-photo {
            width: 90px;
            height: 90px;
            padding-left: 0px;
            border-radius: 0%;
            margin-top: 0px;
        }
    }

    .margin-box {
        color: #FFFFFF;
        margin-top: 64px;
    }

}
</style>