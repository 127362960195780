<template>
  <NavBar />
  <RouterView></RouterView>
  <div style="position:fixed;text-align:center;bottom:0; margin:0 auto; width:100%; color: #5c6b77 ; margin-top: 20px;">
    <!-- <a target="_blank" style="color: white; text-decoration:none ; font-size: 15px;"
      href="https://beian.miit.gov.cn/">粤ICP备2023006794号
    </a> -->
    &nbsp;
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'

export default {
  components: {
    NavBar,
  }
}
</script>

<style>
body {
  color: white;
  background-image: url("http://down.jiwaicat.top/blog7ff7ad911cd37.webp");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
