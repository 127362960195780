<template>
  <PasteBinCard>
    <div class="context">
      <h1 style="text-align: center;">{{ title }}</h1>
      <hr>
      <v-md-preview :text="markdown"></v-md-preview>
      <ContentField></ContentField>
    </div>
  </PasteBinCard>
</template>

<script>
import PasteBinCard from '../../components/PasteBinCard.vue';
import ContentField from '../../components/CommentField.vue';
import PasteBinViewService from '@/api/PasteBinViewService';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    PasteBinCard,
    ContentField
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  setup() {
    let markdown = ref('');
    let title = ref('');
    const route = useRoute();
    const id = parseInt(route.params.ArticleId);// 从路由拿id ajax请求对应id 后端从request中拿id

    (async () => {
      let res = await PasteBinViewService.getArticleById(id);
      title.value = res.title;
      markdown.value = res.content;
    })();

    return {
      markdown,
      title,
    }
  }
}
</script>

<style scoped></style>
