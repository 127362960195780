import http from "@/main";
import $ from 'jquery'


function pullComment(attach_id) {
    return new Promise((resolve) => {
        http.get('/postComment/' + attach_id + "/")
            .then(res => {
                resolve(res.data);
            })
    });
}

function getLocation() {
    // 使用腾讯地图api获取请求IP的定位信息
    return new Promise((resolve) => {
        // axios 不支持jsonp跨域请求
        const tc_map_key = "5Z4BZ-TDFKG-OMJQB-QEMIT-5GFBF-5OFSH";
        $.ajax({
            url: "https://apis.map.qq.com/ws/location/v1/ip",
            type: "GET",
            dataType: 'jsonp',
            crossDomin: true,
            xhrFields: {
                WithCredentials: true
            },
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST',
                'X-Content-Type-Options': 'nosniff'
            },
            data: {
                key: tc_map_key,
                output: 'jsonp',
            }
        }).then(res => { resolve(res) })
    });
}
function sendComment() {
    // (async () => {
    //     let location_data = await getLocation();
    //     console.log(location_data);
    //     return new Promise((resolve) => {
    //         http.post('/sendpostComment/', data)
    //             .then(res => {
    //                 resolve(res.data);
    //                 console.log(location_data);
    //             })
    //     });
    // })();

    let res = getLocation();
    console.log(res);
}

const CommentFieldViewService = {
    pullComment,
    getLocation,
    sendComment
}
export default CommentFieldViewService