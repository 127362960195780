<template>
  <ContentField>
    <div>
      <div> ----- 开发中 ----- </div>
      <input type="file" @change="uploadFile" />
      <button @click="submitFile">上传</button>
    </div>
  </ContentField>
</template>

<script>
import ContentField from '../components/ContentField.vue'
export default {
  components: {
    ContentField,
  },
  beforeRouteEnter(from, to, next) {
    document.title = '文件上传';
    next();
  },
  methods: {
    uploadFile(event) {
      this.file = event.target.files[0];
    },
    submitFile() {
      var formData = new FormData();
      formData.append('file', this.file);
      var xhr = new XMLHttpRequest();
      // xhr.open('POST','http://pcat.one/send/',true) ; 
      xhr.onload = function () {
        if (xhr.status === 200) {
          console.log('表单上传成功');
        } else {
          console.log('表单上传失败');
        }
      };
      xhr.send(formData);
    }
  },
}
</script>

<style scoped></style>