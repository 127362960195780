<template>
    <ContentField>
        <div>本站导航</div>
        <div class="nav-box" style="display: flex;">
            <div class="nav-item">
                <router-link :class="'nav-link title'" :to="{ name: 'todo' }">Todo✔️</router-link>
                <div class="info">待办事项</div>
            </div>

            <div class="nav-item">
                <router-link :class="'nav-link title'" :to="{ name: 'hacker' }">定向🏃‍♂️</router-link>
                <div class="info">定向越野二维码</div>
            </div>

            <div class="nav-item">
                <router-link :class="'nav-link title'" :to="{ name: 'ranklist_index' }">赛博皮皮猫🐈</router-link>
                <div class="info">其实是本站图床的预览</div>
            </div>

            <div class="nav-item">
                <router-link :class="'nav-link title'" :to="{ name: 'submitcode' }">Pastebin🗒️</router-link>
                <div class="info">在线剪贴板</div>
            </div>

            <div class="nav-item">
                <router-link :class="'nav-link title'" :to="{ name: 'getanswer' }">抢答器✋</router-link>
                <div class="info">在线抢答器</div>
            </div>

            <div class="nav-item">
                <router-link :class="'nav-link title'" :to="{ name: 'getanswerAdmin' }">抢答器 | admin⚠️ </router-link>
                <div class="info">抢答器后台</div>
            </div>
        </div>

    </ContentField>
</template>
  
<script>
import ContentField from '../components/ContentField.vue'
export default {
    components: {
        ContentField,
    },
    setup() {

        return {

        }
    }
}
</script>
  
<style scoped>
.title {
    color: rgb(233, 198, 133);
    font-size: larger;
}

.info {
    font-size: medium;
}

.nav-item {
    height: 100px;
    margin: 20px 20px 20px 20px;
    font-family: "宋体", SimSun, serif;
}

@media screen and (max-width: 780px) {
    .nav-box {
        display: block;
        flex-direction: column;
    }

    .nav-item {
        height: 20px;
        margin: 40px 0px 20px 0px;
    }
}
</style>