<template>
    <ContentField>
        <div>
            <div class="title" style="text-align: center; height: 40%;
                    font-size: 35px; font-style: italic;
                    font-weight: 250; letter-spacing: 1px;
                    background-color: rgb(68,68,68);
                    width: 60%; margin: auto; 
                    padding-top: 10px; padding-bottom: 10px;
                    border-radius: 5px;">
                ToDo
            </div>
            <img v-bind:src="img" alt="" @click="click_maid()"
                style="height: 32vh; max-width: 165px; float: left; margin-top: 10%;">
            <div class="task-body" style="width: 70%; text-align: center; 
                    margin: auto;">

                <!-- 拉取数据库所有待办 -->
                <div v-for="(todo) in ToDoList.list" :key="todo" style="margin-top: 20px;">
                    <div class="todo-box" style="text-align: left;">
                        {{ todo.title }}
                        <button @click="changeFolder(todo)" type="button" class="btn btn-success"
                            style="float: right; margin-left: 10px;">
                            {{ todo.isFold ? 'ᐃ' : 'ᐁ' }}
                        </button>
                        <!-- <button @click="finish(todo)" type="button" class="btn btn-success" style="float: right;">
                            完成
                        </button> -->
                    </div>

                    <div class="todo-content" v-if="todo.isFold">
                        <div class="todo-content-headline" style="display: flex; height: 20px;">
                            <div class="todo-content-headline-span">完成度: {{ todo.headline.percent }}</div>
                            <div class="todo-content-headline-span">重要程度: {{ todo.headline.level }}</div>
                            <div class="todo-content-headline-span">创建时间: {{ todo.headline.createtime }}</div>
                        </div>

                        <pre class="" style="margin-top: 10px;">{{ todo.content }}</pre>
                    </div>
                </div>

                <span>其他操作请登录后台......</span>
                <!-- <input v-model="input" type="text" style="margin-top: 20px;" class="form-control" id="usercode"> -->
                <!-- <button @click="add_todo()" type="submit" class="btn btn-primary" style="margin-top: 20px;">添加</button> -->
            </div>
        </div>

    </ContentField>
</template>

<script>
import $ from 'jquery';
import { ref, reactive } from 'vue';
import ContentField from '../components/ContentField.vue'
import ToDoViewService from '../api/ToDoViewService'
export default {
    components: {
        ContentField
    },
    method: {

    },
    beforeRouteEnter(to, from, next) {
        document.title = '今日待办'
        next()
    },
    methods: {
        // 下拉展开具体内容
        changeFolder(todo) {
            let is_fold = todo.isFold

            if (is_fold === false) {
                // 请求对应todo的具体信息
                (async (todo) => {
                    // let id = todo.id;
                    console.log(todo);

                    let data = await ToDoViewService.getToDoById(todo.id)
                    todo.headline = data
                    todo.content = data.content

                })(todo)
            }
            todo.isFold = !is_fold  // 最后再改变可见性，因为有请求延迟(即使是await)
        }
    },
    setup() {
        const ToDoList = reactive({
            "choices": null,
            "list": null
            /*  
                list存储 
                {
                    id: null,
                    title: null,
                    isFold: null,
                    headline: {
                        percent , 
                        level , 
                        createtime 
                    }
                    content: null   
                }
                的列表*/
        })

        let todolist = ref('');
        let input = ref('');
        const img1 = 'http://pcat.one/upload/img/wuyu.png';
        const img2 = 'http://pcat.one/upload/img/wuyu2.png';
        let img = ref(''); img.value = img1;
        console.log(img.value);

        function click_maid() {
            alert("早...早上好主人～")
        }

        function change_img() {
            if (img.value === img1) {
                img.value = img2;
            } else img.value = img1;

            alert("💗💗💗幸苦了，狗修金sama！💗💗💗")

        }


        function finish(id) {
            console.log("click button")
            $.ajax({
                url: "http://pcat.one/hhh/",
                type: "POST",
                data: JSON.stringify({ code: id }),
                headers: {
                    "Content-Type": "application/json"
                },
                success() {
                    $.ajax({
                        url: "http://pcat.one/todo/",
                        type: "GET",
                        success(resp) {
                            todolist.value = resp;
                            console.log("success")
                            change_img();
                        }
                    })
                }
            })
        }

        function add_todo() {
            console.log(input.value)
            $.ajax({
                url: "http://pcat.one/addtodo/",
                type: "POST",
                data: {
                    content: input.value,
                },
                success() {
                    $.ajax({
                        url: "http://pcat.one/todo/",
                        type: "GET",
                        data: {},
                        success(resp) {
                            todolist.value = resp;
                        }
                    })
                }
            })
        }

        // 初始化 todo 简洁列表
        (async () => {
            let data = await ToDoViewService.getSimpleToDoList();
            ToDoList.choices = data.choices;

            let len = data.titles.length;
            let list = Array();

            for (let i = 0; i < len; i++) {
                list.push({
                    id: data.titles[i].id,
                    title: data.titles[i].title,
                    isFold: false,
                    headline: {
                        "percent": null,
                        "level": null,
                        "createtime": null
                    },
                    content: null
                })
            }
            ToDoList.list = list;
            console.log(ToDoList.list)
        })()

        return {
            ToDoList, add_todo, input, finish,
            change_img, img, click_maid,
        }
    },

}
</script>

<style scoped>
.todo-box {
    border-radius: 4px;
    height: 37px;
}

.todo-box:hover {
    background-color: green;
}


.todo-content-headline-span {
    margin-left: 50px;
    font-size: small;
}
</style>