<template>
    <div class="container">
        <div class="form-floating">
            <textarea v-model="content" class="form-control" placeholder="Leave a comment here" id="floatingTextarea2"
                style="height: 150px"></textarea>
            <label for="floatingTextarea2">登陆后方可评论~</label>
            <button type="button" class="btn btn-success" @click="sendComment()"
                style="float: right; margin-top: 20px; ; width: 20%;">评论</button>
        </div>

        <div class="comments-list" v-for="comment in comments" :key="comment">
            <div class="comment-box">
                <div class="info" style="display: flex;">
                    <div class="info-username" style="font-weight: bold; margin-top: 20px;">{{
                comment.author }}</div>
                    <div class="info-area"
                        style="color: rgb(233, 198, 133) ; font-size: 0.8em; font-weight: lighter; margin-left: 20px; margin-top: 23px;">
                        来自：{{
                comment.content_location }}</div>
                    <div class="info-area" style="color: gray; margin-left: 30px; margin-top: 20px;">
                        {{ comment.createtime.substr(0, 10) + " " + comment.createtime.substr(11, 5) }}</div>
                </div>
            </div>
            <pre class="comment-content">{{ comment.content }}</pre>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import CommentFieldViewService from '@/api/CommentFieldViewService';
import router from '@/router';

export default {
    setup() {
        let content = ref('');
        let comments = ref('');
        const route = useRoute();
        const store = useStore();
        const attach_id = route.params.ArticleId;

        // 拉取评论
        (async () => {
            let data = await CommentFieldViewService.pullComment(attach_id);
            comments.value = data;
        })();


        // test 
        (async () => {
            let data = await CommentFieldViewService.getLocation();
            console.log(data);
            console.log("ok")
        })();
        // 发送评论
        const sendComment = () => {
            // 未登录跳转
            let is_login = store.state.user.is_login
            console.log(is_login);
            if (!is_login) {
                router.push('/myspace/login')
            }

            const input = {
                "author": store.state.user.username,
                "attach_id": attach_id,
                "content": content.value,
                // "content_location": content_location.value,
            };


            (async () => {
                let data = await CommentFieldViewService.sendComment(input);
                console.log(data);
                console.log("ok")
            })();
        }

        // 使用腾讯地图api获取请求IP的定位信息
        // $.ajax({
        //     url: "https://apis.map.qq.com/ws/location/v1/ip",
        //     type: "GET",
        //     dataType: 'jsonp',
        //     crossDomin: true,
        //     xhrFields: {
        //         WithCredentials: true
        //     },
        //     headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         'Access-Control-Allow-Methods': 'GET,POST',
        //         'X-Content-Type-Options': 'nosniff'
        //     },
        //     data: {
        //         key: tc_map_key,
        //         output: 'jsonp',
        //     },
        //     success(resp) {
        //         console.log(resp);
        //         // console.log("success!");
        //         // let nation = resp.result.ad_info.nation;
        //         // let province = resp.result.ad_info.province;
        //         // let city = resp.result.ad_info.city;

        //         // content_location.value = nation + "  " + province + "  " + city;


        //         // // POST 数据库归档


        //         // let access = localStorage.getItem('access');
        //         // $.ajax({
        //         //     url: "http://pcat.one/sendpostComment/",
        //         //     type: "POST",
        //         //     data: {
        //         //         author: store.state.user.username,
        //         //         attach_id: attach_id,
        //         //         content: content.value,
        //         //         content_location: content_location.value,
        //         //     },
        //         //     headers: {
        //         //         'Authorization': "Bearer " + access,
        //         //     },
        //         //     success(resp) {
        //         //         console.log(resp);
        //         //         // pullComment();
        //         //     }
        //         // })
        //     }, error(err) {
        //         console.log(err);
        //     }
        // })


        return {
            attach_id,
            comments, sendComment
        }
    }

} 
</script>

<style scoped>
.comment-content {
    background-color: gray;
    padding-left: 20px;
    padding-top: 20px;
}

.comment-box {
    height: 50px;
    background-color: rgb(20, 20, 20);
    border-radius: 6px 6px 0px 0px;
    padding-left: 20px;
}

.comments-list {
    clear: both;
    margin-top: 80px;
}

.form-control {
    background-color: rgb(20, 20, 20);
    border-color: rgb(20, 20, 20);
    color: white;
}
</style>