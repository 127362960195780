<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary navbar-dark" style="background-color: rgba(31, 34, 40, 0.6);">
    <div class="container">
      <router-link class="nav-link mysite-logo" :to="{ name: 'pk_index' }">Hnitro</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
        aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :class="route_name == 'pk_index' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'pk_index' }">首页</router-link>
          </li>
          <li class="nav-item">
            <router-link :class="route_name == 'tutorial' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'tutorial' }">教程</router-link>
          </li>
          <li class="nav-item">
            <router-link :class="route_name == 'record_index' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'record_index' }">博客</router-link>
          </li>
          <li class="nav-item">
            <router-link :class="route_name == 'compiler' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'compiler' }">在线编译器</router-link>
          </li>
          <li class="nav-item">
            <router-link :class="route_name == 'todo' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'todo' }">Todo</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link :class="route_name == 'contestInfo' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'contestInfo' }">文件暂存</router-link>
          </li> -->
          <!-- <li class="nav-item">
            <router-link :class="route_name == 'gettable' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'gettable' }">教务系统</router-link>
          </li> -->
          <li class="nav-item">
            <router-link :class="route_name == 'navmysite' ? 'nav-link active' : 'nav-link'"
              :to="{ name: 'navmysite' }">本站导航</router-link>
          </li>
        </ul>

        <!-- 没有登陆 -->
        <ul class="navbar-nav" v-if="!$store.state.user.is_login">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'login' }">登陆</router-link>
          </li>
        </ul>

        <!-- 登陆 -->
        <ul class="navbar-nav" v-if="$store.state.user.is_login">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'pk_index' }">{{ $store.state.user.username }}</router-link>
          </li>
        </ul>

      </div>
    </div>
  </nav>
</template>


<script>
import { useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    let route_name = computed(() => route.name)
    let username = ref('登录');

    console.log(store.state.user.is_login)  // 这样去访问store里面的变量

    return {
      route_name,
      username,
    }
  }
}
</script>

<style scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.mysite-logo {
  font-style: oblique 40deg;
}

.nav-link {
  margin-left: 20px;
}
</style>