<template>
    <ContentField>
        <div class="register-box">
            <h2 style="margin-bottom: 20px;">爬</h2>
            <label class="form-label">用户名</label>
            <input v-model="username" id="username" class="form-control" aria-labelledby="passwordHelpBlock">

            <label for="inputPassword5" class="form-label">密码</label>
            <input v-model="password" type="password" id="password" class="form-control"
                aria-labelledby="passwordHelpBlock">

            <button @click="submit()" type="button" class="btn btn-primary" style="width: 50%; margin-top: 20px;">
                爬取</button>
        </div>
    </ContentField>
</template>

<script>
import { ref } from 'vue';
import ContentField from '../components/ContentField.vue'

export default {
    components: {
        ContentField,
    },
    setup() {
        let username = ref('');
        let password = ref('');
        function submit() {
            console.log(username.value);
            console.log(password.value);
        }

        return {
            username,
            password,
            submit,
        }
    }
}

</script>

<style scoped>
.register-box {
    text-align: center;
    margin: auto;
    font-family: '宋体', SimSun, serif;
    width: 30%;
}

input {
    text-align: center;
    margin-bottom: 2%;
}
</style>