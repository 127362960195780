import http from "@/main";
import OriginRequestGet from "@/main";

function updatePageObj(PageObj, res) {
    PageObj.previous = res.data.previous
    PageObj.next = res.data.next

    // 更新按键状态
    if (PageObj.previous === null) PageObj.showPre = false
    else PageObj.showPre = true

    if (PageObj.next === null) PageObj.showNext = false
    else PageObj.showNext = true

    return PageObj
}

function getBlogList(PageObj) {
    return new Promise((resolve) => {
        http.get('/post/?page=1')
            .then(res => {
                resolve(res.data.results);
                // 更新分页状态
                updatePageObj(PageObj, res)
            })
    });
}

function getBlogCategory() {
    return new Promise((resolve) => {
        http.get('/getBlogCategory/')
            .then(res => {
                resolve(res.data);
            })
    });
}

function ClickPageButton(option, PageObj) {
    if (option === 'next' && PageObj.next === null) return
    if (option === 'previous' && PageObj.previous === null) return


    if (option === 'next') {
        // 下一页
        let url = PageObj.next
        let request = OriginRequestGet(url, '')
        return new Promise((resolve) => {
            request.then(res => {
                updatePageObj(PageObj, res);
                console.log("update")
                resolve(res.data);
            })
        })
    } else if (option === 'previous') {
        // 上一页
        let url = PageObj.previous
        let request = OriginRequestGet(url, '')
        return new Promise((resolve) => {
            request.then(res => {
                updatePageObj(PageObj, res);
                console.log("update")
                resolve(res.data);
            })
        })
    }
}

const BlogPostViewService = {
    getBlogCategory,
    getBlogList,
    ClickPageButton
}
export default BlogPostViewService