<template>
    <ContentField>
        <div class="register-box">
            <h2 style="margin-bottom: 20px;">注册</h2>
            <label for="inputPassword5" class="form-label">用户名</label>
            <input type="password" id="inputPassword5" class="form-control" aria-labelledby="passwordHelpBlock">

            <label for="inputPassword5" class="form-label">密码</label>
            <input type="password" id="inputPassword5" class="form-control" aria-labelledby="passwordHelpBlock">


            <label for="inputPassword5" class="form-label">确认密码</label>
            <input type="password" id="inputPassword5" class="form-control" aria-labelledby="passwordHelpBlock">

            <button type="button" class="btn btn-primary" style="width: 50%;">
                注册</button>
        </div>
    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'
export default {
    components: {
        ContentField,
    }
}

</script>

<style scoped>
.register-box {
    text-align: center;
    margin: auto;
    font-family: '宋体', SimSun, serif;
    width: 30%;
}

input {
    text-align: center;
    margin-bottom: 2%;
}
</style>