import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';
import axios from 'axios';

const app = createApp(App);

app.use(VMdPreview);
VMdPreview.use(vuepressTheme, {
  Prism,
  // extend(md) {
  //   md.set(option).use(plugins)
  // }
});

app.use(store).use(router).use(router).mount('#app');

// Wrapper Axios 
const http = axios.create({
  // baseURL: 'http://192.168.31.7:8181',    // 调试url
  baseURL: window.location.origin, // 使用当前窗口的URL作为baseURL
})

// 某些请求需要覆盖baseURL 
export function OriginRequestGet(url, config) {
  return http.get(url, config)
}

// 对Axios实例添加拦截器
http.interceptors.request.use(
  function (response) {
    console.log("请求成功 --- 拦截器输出")
    return response;
  }, function (error) {
    console.log("请求失败 --- 拦截器输出")
    return Promise.reject(error);
  }
);

http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// 将数据转换为表单数据格式 , Axios 默认发送Json数据，而Django默认解析表单数据
export default http;
