<template>
    <ContentField>
        <div class="register-box">
            <h4 style="margin-bottom: 20px;">登陆</h4>
            <form action="">
                <label for="inputUsername" class="form-label">用户名</label>
                <input v-model="username" type="username" id="username2" class="form-control">

                <label for="inputPassword" class="form-label">密码</label>
                <input v-model="password" type="password" id="password2" class="form-control"
                    aria-labelledby="passwordHelpBlock">
            </form>
            <button type="button" class="btn btn-primary" style="width: 50%;" @click="submit()">
                登陆</button>
        </div>
    </ContentField>
</template>

<script>
import $ from 'jquery'
import router from '@/router'
import ContentField from '../../components/ContentField.vue'
import { ref } from 'vue'
import { useStore } from 'vuex';
import jwt_decode from 'jwt-decode';

export default {
    components: {
        ContentField,
    },
    setup() {
        let username = ref(''); let password = ref('');
        let token = localStorage.getItem('access');
        const store = useStore();

        // 自动登陆
        if (token === null) {
            router.push('/myspace/login/');
            console.log("token unexisted!")
        } else {
            let refresh = localStorage.getItem('refresh');
            console.log(refresh);

            // 用refresh拉去最新的access 
            $.ajax({
                url: "http://pcat.one/api/token/refresh/",
                type: "POST",
                data: { refresh: refresh },
                success(resp) {
                    // console.log(resp.access);

                    let access_obj = jwt_decode(resp.access);
                    let user_id = access_obj.user_id;
                    console.log("user_id: " + user_id)

                    store.dispatch("set_login", {   // 传入的是一个对象
                        access: resp.access,
                        refresh: refresh,
                        user_id: user_id,
                        success(resp) {
                            console.log(resp);
                        }
                    })
                }
            })
        }

        const submit = () => {
            store.dispatch("login",     // 执行user中的login actions
                {
                    username: username.value,
                    password: password.value,
                    success() {
                        console.log("emit success!")
                        router.push({ name: "home" });
                    },
                    error() {
                        console.log("密码错误")
                    }
                }
            )
        }

        return {
            submit, username, password,
        }
    }
}

</script>

<style scoped>
.register-box {
    text-align: center;
    margin: auto;
    font-family: '宋体', SimSun, serif;
    width: 30%;
}

input {
    text-align: center;
    margin-bottom: 2%;
}

@media screen and (max-width: 780px) {
    .register-box {
        width: 230px;
    }
}
</style>