<template>
    <ContentField>
        <div class="head">
            <div class="title" style=" font-size: 27px; float: left;
            font-family: '宋体', SimSun, serif; ">Pastebin</div>

            <button @click="submit()" type="button" class="btn btn-warning submit-btn"
                style="width: 100px; float: right; margin-top: 2px; font-family: '宋体', SimSun, serif;">上传</button>
        </div>
        <div style="clear: both; margin-top: 70px;"></div>
        <div class="warn" style="font-family: '宋体', SimSun, serif;">注意：所有pastebin会在创建一天后自动销毁</div>

        <br>
        <input type="text" class="underline-input" placeholder="Title..." v-model="title">
        <input type="text" class="underline-input" placeholder="Author..." v-model="author" style="margin-left: 10%">
        <div style="height: 20px;"> </div>

        <v-ace-editor v-model:value="content" :min-lines="20" :max-lines="300" showLineNumbers=false lang="markdown"
            theme="kuroir" style="" :options="{
                enableBasicAutocompletion: true, //启用基本自动完成
                enableSnippets: true,
                enableLiveAutocompletion: true, // 启用实时自动完成
                fontSize: 14, //设置字号
                tabSize: 4, // tab大小
                showPrintMargin: false, //去除编辑器里的竖线
                showLineNumbers: false,
                highlightActiveLine: true,
            }" />
    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'
import { VAceEditor } from 'vue3-ace-editor'
import { ref } from 'vue';
import router from '@/router';
import PasteBinViewService from '@/api/PasteBinViewService'

export default {
    components: {
        ContentField,
        VAceEditor,
    },
    beforeRouteEnter(from, to, next) {
        document.title = 'Pastebin';
        next();
    },
    mounted() {

    },
    setup() {
        let content = ref('');
        let title = ref(''); let author = ref('');

        function Redirect_paste(PasteId) {
            router.push({
                name: "pasteTmp",
                params: {
                    PasteId
                },
            })
        }

        function submit() {
            const formData = new FormData();
            formData.append('title', title.value);
            formData.append('author', author.value);
            formData.append('content', content.value);

            // 上传Pastebin
            (async (formData) => {
                let data = await PasteBinViewService.postNewPasteBin(formData);
                console.log(data)
                // 成功就跳转
                if (data.result === 'success') {
                    Redirect_paste(data.reback_id);
                }
            })(formData)
        }

        return {
            submit, content, title, author, Redirect_paste,
        }
    }
}
</script>

<style scoped>
.underline-input {
    border: none;
    border-bottom: 1px solid rgb(233, 198, 133);
    outline: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.2);
    font-family: '宋体', SimSun, serif;
    height: 40px;
    font-size: 2.5vh;
    width: 20%;
}

::placeholder {
    color: rgb(255, 236, 201);
}
</style>