<template>
    <ContentField>404</ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue' 
export default {
    components: {
            ContentField,
    },
    beforeRouteEnter(from,to,next) {
        document.title = '404' ;
        next() ;
    }
}
</script>

<style scoped>
</style>