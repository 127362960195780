<template>
    <ContentField>
        <div class="getAnswer-box" style="margin: auto; width: 60%;">
            <div class="Now">
                倒计时： {{ Time }}
            </div>

            <label style="margin-top: 20px;" for="inputPassword5" class="form-label">用户ID：</label>
            <input v-model="usercode" type="username" id="inputPassword5" class="form-control" style="width: 100%;"
                aria-labelledby="passwordHelpBlock">

            <div class="btn-box">
                <button style="margin-top: 20px; width: 30%;" @click="submit()" type="button"
                    class="btn btn-danger">抢答</button>
                <button style="margin-top: 20px; width: 30%; margin-left: 40%; " type="button" @click="reset()"
                    class="btn btn-primary">校准</button>
            </div>
        </div>

    </ContentField>
</template>

<script>
import ContentField from '../components/ContentField.vue'
import { $ } from 'jquery'
import { ref } from 'vue'


export default {
    components: {
        ContentField,
    },
    setup() {
        let usercode = ref('');
        let Time = ref('3');
        let isstarted = false;

        const countdown = setInterval(function () {
            if (Time.value <= 0) {
                clearInterval();
                isstarted = true;
                return;
            }
            Time.value = Time.value - 1;
        }, 1000)

        // 校准
        function reset() {
            console.log("reset");
            $.ajax({
                url: 'http://pcat.one/getTimeStamp/',
                type: 'GET',
                data: {
                    id: usercode.value
                },
                success(resp) {
                    let time = resp[0].TimeStamp;
                    let hour = time.substr(11, 2);
                    let minute = time.substr(14, 2);
                    let second = time.substr(17, 2);
                    console.log("hour: " + hour + " minute: " + minute + " second: " + second);

                    const now = new Date();
                    console.log(now);
                    Time.value = 3600 * (hour - now.getHours()) + 60 * (minute - now.getMinutes()) + second - now.getSeconds();
                }
            })

        }

        // 抢答
        function submit() {
            if (isstarted) {
                const now = new Date();
                console.log(now);
                $.ajax({
                    url: 'http://pcat.one/getAnswer/',
                    type: 'POST',
                    data: {
                        id: usercode.value,
                        time: now,
                    },
                    success(resp) {
                        console.log(resp);
                    }
                })
            } else {
                console.log("抢答尚未开始！")
            }
        }

        return {
            submit,
            usercode,
            reset,
            countdown, Time, isstarted
        }
    }

}
</script>

<style scoped>
.Now {
    font-size: 30px;
    text-align: center;
    font-family: '宋体', SimSun, serif;
    color: rgb(233, 198, 133);
}
</style>
