<template>
    <ContentField>
        <div class="head">
            <div class="title" style=" font-size: 27px; float: left;
            font-family: '宋体', SimSun, serif; ">在线编译器</div>
            <button @click="reset()" type="button" class="btn btn-secondary submit-btn"
                style="float: right; margin-top: 2px; font-family: '宋体', SimSun, serif;">重置</button>
        </div>
        <div style="clear: both;"></div>

        <v-ace-editor v-model:value="content" :min-lines="20" :max-lines="300" lang="c_cpp" theme="chrome" style=""
            :options="{
                enableBasicAutocompletion: true, //启用基本自动完成
                enableSnippets: true, // 启用代码段
                enableLiveAutocompletion: true, // 启用实时自动完成
                fontSize: 22, //设置字号
                tabSize: 4, // tab大小
                showPrintMargin: false, //去除编辑器里的竖线
                highlightActiveLine: true,
            }" />

        <div class="btn-div mb-3">
            <div class="submit_btn" style="text-align: right; ">
                <div class="result" style="margin-top: 30px; margin-left: 20px; text-align: left; float: left;">状态：
                    <div v-if="running" class="spinner-border text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <!-- 运行反馈栏 -->
                    <span v-if="result_finish" style="color: #00ff99; font-size: 20px;">
                        Finished</span>
                    <span v-if="result_err" style="color: #ff3300; font-size: 20px;">Compile Error</span>
                </div>
                <button @click="runCode" type="button" class="btn btn-success submit-btn">
                    Submit</button>
            </div>
        </div>
        <div class="operator-box mb-3" style="border-radius: 4px; 
            height: 200px; clear: both;">
            <div class="item" style="margin-left: 20px ; margin-right: 20px;">
                <label for="input_data" class="form-label" style="font-size: 15px; ">输入</label>
                <textarea ref="textarea" v-model="input_data" class="form-control" id="exampleFormControlTextarea1"
                    rows="3"></textarea>
            </div>

            <div class="item" style="margin-left: 20px ; margin-right: 20px; border-radius: 4px;">
                <label for="input_data" class="form-label" style="font-size: 15px; ">输出</label>
                <div class="background-vm" style="background-color: white; border-radius: 4px; 
                    color: black; height: auto; background-color: rgb(242,243,245);">
                    <pre style="margin-left: 20px;">{{ output }}</pre>
                    <!-- 用pre就可以换行渲染json -->
                </div>
            </div>
        </div>

    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'
import { VAceEditor } from 'vue3-ace-editor'
import ace from "ace-builds";
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-c_cpp';
import { ref } from 'vue';
import http from "@/main";
import autosize from 'autosize';


ace.config.set(
    "basePath",
    "https://cdn.jsdelivr.net/npm/ace-builds@" +
    require("ace-builds").version +
    "/src-noconflict/")

export default {
    components: {
        ContentField,
        VAceEditor,
    },
    beforeRouteEnter(from, to, next) {
        document.title = '在线编译器';
        next();
    },
    mounted() {
        autosize(this.$refs.textarea)
    },
    setup() {
        let content = ref('#include <iostream>\n#include <algorithm>\n\nusing namespace std ;\n\ntypedef long long LL ;\n\nint main()\n{\n\n    return 0 ; \n}');
        let backid = ref('');
        let output = ref('');
        let input_data = ref('');
        let running = ref(false);
        let result_finish = ref(false);
        let result_err = ref(false);

        function reset() {
            console.log("click reset");
            content.value = '#include <iostream>\n#include <algorithm>\n\nusing namespace std ;\n\ntypedef long long LL ;\n\nint main()\n{\n\n    return 0 ; \n}';
        }

        function runCode() {
            console.log(content.value);
            console.log(input_data.value);
            running.value = true;

            result_err.value = false;
            result_finish.value = false;

            const formData = new FormData();
            formData.append('code', content.value);
            formData.append('input_data', input_data.value);

            http.post("/compile/", formData)
                .then(resp => {
                    resp = resp.data
                    running.value = false;
                    backid.value = resp;    // ? 
                    console.log(resp);
                    output.value = resp.output;

                    if (output.value === undefined)  // 返回报错信息
                    {
                        output.value = resp.error
                        result_err.value = true;
                    }
                    if (result_err.value === false) result_finish.value = true;
                })
        }
        return {
            content,
            runCode,
            backid,
            output,
            input_data,
            running,
            result_finish,
            result_err,
            reset,
        }
    },
}
</script>

<style scoped>
.output-box {
    background-color: white;
    clear: both;
    height: 30px;
    border-radius: 5px;
}

.submit-btn {
    margin-top: 20px;
    width: 200px;
    margin-bottom: 20px;
}

.editor-bar-content {
    height: 60px;
    background-color: white;
}

.form-select {
    width: 140px;
    margin-left: auto;
    margin-right: 20px;
    margin-top: auto;
}

.col {
    border: solid;
}
</style>