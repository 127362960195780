<template>
    <PostBar>
    </PostBar>
    <div class="text-center" style="display: flex;">
        <div class="leftside blog-box" style="font-size: 120%; color: #CBC6C0;">
            <div class="post-category">
                <h3 class="category-title" style="margin: 20px 0px 20px 0px;">分类专栏</h3>

                <div class="blog-categoryDetail">
                    <span class="tag" v-for="(count, tag) in blog_category" @click="gotoAttachedTag(tag)" :key="tag">{{
                        tag }}</span>
                    <hr>
                </div>

            </div>

        </div>
        <div class="rightside">

            <div class="container">
                <!-- 动态展示文章列表 -->
                <div v-for="article in articles" :key="article.id" class="blog-box" style="display: flex;">
                    <div class="blog-box-left" style="margin: auto; width: 35%;">
                        <img :src="article.pic_url" :srcset="article.pic_url" sizes="(max-width: 600px) 480px,
         800px" alt="blog-info-pic" class="blog-box-pic" @click="OpenArticle(article.id)">
                    </div>

                    <div class="blog-box-right-info">
                        <div class="blog-box-right-info-title" @click="OpenArticle(article.id)">{{ article.title }}
                        </div>
                        <div class="blog-box-right-info-date">
                            {{ article.createtime.substr(0, 10) + " " +
                        article.createtime.substr(11, 8) }}</div>

                        <!-- 文章标签 -->
                        <div class="tagdiv">
                            <span v-for="tag in article.tags" :key="tag" class="tag"> {{ tag }} </span>
                        </div>
                    </div>
                </div>
                <button v-if="CheckPaginationBtn('previous')" type="button" class="btn btn-secondary"
                    @click="HandleClick('previous')"
                    style="float: left; margin-top: 20px; margin-bottom: 200px;">上一页</button>
                <button v-if="CheckPaginationBtn('next')" type="button" class="btn btn-secondary"
                    @click="HandleClick('next')"
                    style="float: right; margin-top: 20px; margin-bottom: 200px;">下一页</button>
            </div>
        </div>

    </div>
</template>

<script>
import PostBar from '../../components/PostBar.vue'
import { reactive, ref } from 'vue'
import router from '@/router';
import BlogPostViewService from '@/api/BlogPostViewServerice'

export default {
    components: {
        PostBar,
    },
    beforeRouteEnter(to, from, next) {
        document.title = '博客';
        next();
    },
    setup() {
        // Page对象操作分页相关
        const PageObj = reactive({
            previous: null,
            next: null,
            showPre: false, // 是否展示前一页button
            showNext: true,
        })

        // 初始化文章列表
        let articles = ref({});
        (async () => {
            let data = await BlogPostViewService.getBlogList(PageObj);
            console.log(PageObj)
            articles.value = data;
        })();

        // 处理分页
        const HandleClick = (option) => {
            (async () => {
                let res = await BlogPostViewService.ClickPageButton(option, PageObj);
                console.log(res)
                articles.value = res.results;
            })();
        }


        // 上下一页按钮状态
        const CheckPaginationBtn = (option) => {
            if (option === 'previous') return PageObj.showPre
            if (option === 'next') return PageObj.showNext
        }

        // 查看文章
        const OpenArticle = ArticleId => {
            router.push({
                name: "articleprofile",  // 这是按照路由的name匹配而不是路由的字符
                params: {
                    ArticleId, // 路由那里也是名字匹配 我服了
                },
            })
        }

        // 获取文章分类
        let blog_category = ref('');
        (async () => {
            let data = await BlogPostViewService.getBlogCategory();
            blog_category.value = data;
        })();


        const gotoAttachedTag = tag => {
            router.push({
                name: "searchPost",
                params: { mytag: tag }
            })
        }

        return {
            articles,
            blog_category,
            OpenArticle,
            gotoAttachedTag, HandleClick, CheckPaginationBtn
        }
    },
}
</script>

<style scoped>
.blog-box-right-info-title {
    font-size: 20px;
}

.leftside {
    width: 20%;
    margin-left: 10%;
}

.rightside {
    width: 60%;
    margin-right: 10%;
}

.tag {
    font-size: 19px;
    color: rgb(52, 145, 250);
    background-color: #a5cef7;
    font-weight: lighter;
    padding-left: 8px;
    padding-right: 8px;
    border: 0.1rem solid rgb(52, 145, 250);
    border-radius: 5px;
    margin-left: 13px;
    word-break: break-all;
    white-space: normal;
    flex-wrap: wrap;
    flex-basis: calc(50% - 10px);
    /* 一行三个，间距为10px */
}

.btn:hover {
    color: rgb(66, 135, 240);
}

.blog-box {
    height: 20%;
    background-color: rgba(31, 34, 40, 0.6);
    margin-top: 20px;
    border-radius: 7px;
    font-family: Fira Code !important;
    font-size: 1.5em;
    border-radius: 8px;
    font-weight: bold;
}

.blog-box-right-info {
    padding-left: 5%;
    padding-top: 5%;
    color: #CBC6C0;
    width: 40%;
}

.blog-box-left {
    position: relative;
    display: inline-block;
    overflow: hidden;
    border-radius: 8px;
}

.blog-box-left img {
    display: block;
    max-width: 100%;
    transition: transform 0.3s ease-out;
    height: 100%;
    width: 100%;
}

.blog-box-left:hover img {
    transform: scale(1.2);
    cursor: pointer;
}

.blog-box-right-info-date {
    margin-top: 20px;
    font-size: 17px;
    color: rgb(233, 198, 133);
}

.blog-box-right-info-title:hover {
    color: pink;
    cursor: pointer;
}

.blog-categoryDetail:hover {
    color: pink;
    cursor: pointer;
    flex-wrap: wrap;
    /* 如果需要换行 */
    justify-content: flex-start;
    /* 调整对齐方式 */
}

.tagdiv {
    margin-top: 10%;
}

/* 手机端的适配 */
@media screen and (max-width: 780px) {
    .category-title {
        font-size: 14px;
    }

    .blog-categoryDetail {
        font-size: 12px
    }

    .blog-box {
        font-size: 13px;
    }

    .tagdiv {
        margin-top: 0px;
    }

    .tag {
        font-size: 10px;
        margin: 0px 0px 0px 3px;
    }

    .blog-box-right-info-title {
        font-size: 14px;
    }

    .blog-box-right-info-date {
        margin-top: 0px;
        font-size: 10px;
    }

    .blog-box-right-info {
        width: 60%;
    }

    .blog-box-left {
        height: 80px;
    }


    /* 布局css */
    .leftside {
        width: 30%;
        margin-left: 0%;
    }

    .rightside {
        width: 100%;
        margin-right: 5%;
    }

}
</style>