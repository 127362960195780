<template>
    <ContentField>
        <h1>Hnitro Space</h1>
    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue'
import jwt_decode from 'jwt-decode';
import { useStore } from 'vuex';
import $ from 'jquery'

export default {
    components: {
        ContentField,
    },
    beforeRouteEnter(to, from, next) {
        document.title = '首页';
        next();
    }, setup() {
        // 自动登陆
        let token = localStorage.getItem('access');
        const store = useStore();

        if (token !== null) {
            let refresh = localStorage.getItem('refresh');
            // 用refresh拉去最新的access 
            $.ajax({
                url: "http://pcat.one/api/token/refresh/",
                type: "POST",
                data: { refresh: refresh },
                success(resp) {
                    // console.log(resp.access);

                    let access_obj = jwt_decode(resp.access);
                    let user_id = access_obj.user_id;
                    console.log("user_id: " + user_id)

                    store.dispatch("set_login", {   // 传入的是一个对象
                        access: resp.access,
                        refresh: refresh,
                        user_id: user_id,
                        success(resp) {
                            console.log(resp);
                        }
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
h1 {
    font-style: italic;
    text-align: center;
    margin-top: 20px;
    font-size: 120px;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
    color: ba;
}

@media (max-width: 768px) {
    h1 {
        font-size: 24px;
    }
}

@media (min-width: 769px) {
    h1 {
        font-size: 120px;
    }
}
</style>